export function isIdPresentInRecursiveList(item, id) {
  if (item.id === id) return true; // Found the id at the current level

  if (item.items) {
    // Recursively check each item in the 'items' array
    return item.items.some(subItem => isIdPresentInRecursiveList(subItem, id));
  }

  return false; // Id not found
}

export const insertItemInPosition = (list, item, position) => {
  const newList = [...list];
  newList.splice(position, 0, item);
  return newList;
};

export const removeItemFromList = (list, item) => {
  const newList = [...list];
  const index = newList.indexOf(item);

  if (index === -1) return list;

  newList.splice(index, 1);
  return newList;
};

export const changeItemPosition = (list, oldPosition, newPosition) => {
  const item = list[oldPosition];
  const newList = removeItemFromList(list, item);
  return insertItemInPosition(newList, item, newPosition);
};
